import React from 'react'
import * as styles from "./process.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import ScrollContainer from 'react-indiana-drag-scroll'


export const ServicesProcess = () => {
  return (
    <div className={styles.services_process}>
      <h2 className={styles.services_process_intro_title}>Как строится работа</h2>
      <div className={styles.services_process_intro_text}>
        <p className={styles.services_process_intro_p}>Мы&nbsp;работаем полным циклом от&nbsp;проектирования до&nbsp;реализации проекта с&nbsp;его комплектацией и&nbsp;авторским надзором. В&nbsp;зависимости от&nbsp;типа объекта или пожелания клиента мы&nbsp;можем корректировать наш комплекс услуг.</p>
      </div>
      <div className={styles.services_process_wrap}>
        <ScrollContainer className="scroll-container services_process_scroll">
          <div className={styles.services_process_items1}>
            <div className={styles.services_process_item1}>
              <div className={styles.services_process_item1_left}>
                <span className={styles.services_process_item1_span}>1</span>
                <p className={styles.services_process_item1_left_p}>Обмеры</p>
              </div>
              <ul className={styles.services_process_list}>
                <li className={styles.services_process_li}>Выезжаем на&nbsp;объект&nbsp;&mdash; анализируем, осуществляем замер, производим фотофиксацию.</li>
                <li className={styles.services_process_li}>Делаем обмерочные чертежи (планы, разрезы, сечения).</li>
              </ul>
            </div>
            <div className={styles.services_process_item1}>
              <div className={styles.services_process_item1_left}>
                <span className={styles.services_process_item1_span}>2</span>
                <p className={styles.services_process_item1_left_p}>Проектируем</p>
              </div>
              <ul className={styles.services_process_list}>
                <li className={styles.services_process_li}>Создаем несколько вариантов планировочной концепции (до&nbsp;тех пор, пока не&nbsp;получим нужное нам решение).</li>
                <li className={styles.services_process_li}>Создаем образ интерьера в&nbsp;виде фотореалистичной 3D-визуализации.</li>
                <li className={styles.services_process_li}>Разрабатываем проектную документацию.</li>
              </ul>
            </div>
            <div className={styles.services_process_item1}>
              <div className={styles.services_process_item1_left}>
                <span className={styles.services_process_item1_span}>3</span>
                <p className={styles.services_process_item1_left_p}>Реализуем</p>
              </div>
              <ul className={styles.services_process_list}>
                <li className={styles.services_process_li}>Получаем разрешение на&nbsp;строительство и&nbsp;приступаем к&nbsp;реализации.</li>
                <li className={styles.services_process_li}>Сопровождаем строительство и&nbsp;комплектуем объект всеми необходимыми материалами, мебелью, оборудованием.</li>
              </ul>
              <div className={styles.services_process_item1_line}></div>
            </div>
          </div>
        </ScrollContainer>
        <div className={styles.services_process_items2}>
          <div className={styles.services_process_item2}>
            <div className={styles.services_process_item2_wrap}>
              <h3 className={styles.services_process_item2_title}>Авторское сопровождение</h3>
              <div className={styles.services_process_item2_text}>
                <p className={styles.services_process_item2_p + " " + styles.services_process_item2_p_sp}>Выстраиваем схему выполнения проектных предложений и контролируем выполнение работ подрядчиков.</p>
                <p className={styles.services_process_item2_p}>Таким&nbsp;образом исключаем возможность возникновения ошибок или недочетов на&nbsp;смежных участках работ.</p>
                <p className={styles.services_process_item2_p + " " + styles.services_process_item2_p_hidden}>Мы&nbsp;не&nbsp;бросаем свою работу на&nbsp;половине пути и&nbsp;сопровождаем проекты до&nbsp;их&nbsp;полной реализации.</p>
              </div>
            </div>
            <StaticImage
              className={styles.services_process_item2_img1}
              src="../../../images/services/services_add_support_new.jpg"
              alt=""
            />
            <StaticImage
              className={styles.services_process_item2_img1_mob}
              src="../../../images/services/services_add_support_new_mob.jpg"
              alt=""
            />
          </div>
          <div className={styles.services_process_item2}>
            <div className={styles.services_process_item2_wrap}>
              <h3 className={styles.services_process_item2_title}>Комплектация объекта</h3>
              <div className={styles.services_process_item2_text}>
                <p className={styles.services_process_item2_p}>Мы&nbsp;берем на&nbsp;себя организацию всех мероприятий, связанных с&nbsp;закупкой и&nbsp;доставкой всех необходимых материалов, образцов, мебели, оборудования.</p>
                <p className={styles.services_process_item2_p + " " + styles.services_process_item2_p_hidden}>Согласование поставок материалов с&nbsp;графиком строительных работ.</p>
              </div>
            </div>
            <StaticImage
              className={styles.services_process_item2_img2}
              src="../../../images/services/services_add_complete_new.png"
              alt=""
            />
            <StaticImage
              className={styles.services_process_item2_img2_mob}
              src="../../../images/services/services_add_complete_new_mob.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}